import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory } from "../../reducers/adminReducer";
import styles from "../../styles/misc/admin.module.css";
import { SiteCategory } from "../../types";
import { PlusIcon } from "../icons/icons";
import CategoryListTile from "./CategoryListTile";

const Categories = () => {
  const [categories]: [SiteCategory[]] = useSelector(
    (state: {
      admin: {
        categories: SiteCategory[];
      };
    }) => [state.admin.categories]
  );

  const dispatch = useDispatch();

  const deleteCat = (category: SiteCategory) => {
    if (category.id) {
      dispatch(deleteCategory(category.id));
    }
  };
  return (
    <div className={styles.dataContainer}>
      <div className={styles.titleContainer}>
        <h3>Kaikki kategoriat</h3>
        <Link to="/admin/createcategory">
          <button>
            <PlusIcon />
            Luo kategoria
          </button>
        </Link>
      </div>
      <div>
        {categories.map((c) => (
          <CategoryListTile
            key={c.id}
            deleteCategory={() => deleteCat(c)}
            category={c}
          />
        ))}
      </div>
    </div>
  );
};
export default Categories;
