/* eslint-disable import/no-extraneous-dependencies */
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import siteReducer from "./reducers/siteReducer";
import mapReducer from "./reducers/mapReducer";
import authReducer from "./reducers/authReducer";
import notificationReducer from "./reducers/notificationReducer";
import adminReducer from "./reducers/adminReducer";

const reducer = combineReducers({
  sites: siteReducer,
  map: mapReducer,
  auth: authReducer,
  notification: notificationReducer,
  admin: adminReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
