import React, { useState } from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import adminstyles from "../../../styles/misc/admin.module.css";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../reducers/notificationReducer";
import { CrossIcon } from "../../icons/icons";

type Props = {
  fields: string[];
  saveFields: (arg: string[]) => void;
};

const PhaseInput = ({ fields, saveFields }: Props) => {
  const dispatch = useDispatch();
  const [currentField, setCurrentField] = useState("");

  const isFieldValid = (fieldName: string) => {
    const isValid = !fields.includes(fieldName);
    if (!isValid) {
      dispatch(setNotification(`Kenttä "${fieldName}" on jo lisätty`));
    }
    return isValid;
  };

  const addField = () => {
    if (currentField.length > 0) {
      if (!isFieldValid(currentField)) return;
      const newFields = [...fields, currentField];
      saveFields(newFields);
      setCurrentField("");
    } else {
      dispatch(setNotification("Lisää kentälle nimi"));
    }
  };

  const removeField = (fieldName: string) => {
    saveFields(fields.filter((f: string) => f !== fieldName));
  };

  return (
    <div className={adminstyles.listInput}>
      <p>Kentät lisätiedoille</p>
      <div className={phaseStyles.textAreaContainer}>
        <textarea
          className={phaseStyles.textArea}
          placeholder="Kentän nimi"
          onChange={(e) => setCurrentField(e.target.value)}
          value={currentField}
        />
        <button onClick={addField} className={adminstyles.icon}>
          +
        </button>
      </div>
      {fields.map((f: string) => (
        <div key={f} className={adminstyles.listItem}>
          <h3>{f}</h3>
          <div onClick={() => removeField(f)}>
            <CrossIcon />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhaseInput;
