import React, { useEffect, useState } from "react";
import adminstyles from "../../styles/misc/admin.module.css";
import {
  createSite,
  editSite,
  resetSiteToEdit,
  setSiteToEdit,
} from "../../reducers/adminReducer";
import { setNotification } from "../../reducers/notificationReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SiteCategory, NewSite, Site } from "../../types";
import SelectDropdown from "../misc/SelectDropdown";
import {
  emptyNewSite,
  getSiteEditData,
  updateSiteCoordinates,
} from "../../utils/misc";

const CreateSite = ({
  chosenCategory,
  siteId,
}: {
  sites?: Site[];
  chosenCategory?: SiteCategory;
  siteId?: string | undefined;
}) => {
  const [categories, siteToEdit]: [SiteCategory[], Site | null] = useSelector(
    (state: {
      admin: {
        categories: SiteCategory[];
        siteToEdit: Site | null;
      };
    }) => [state.admin.categories, state.admin.siteToEdit]
  );
  const dispatch = useDispatch();
  const [newSite, setNewSite] = useState<NewSite>(emptyNewSite);
  const [currentCategory, setCurrentCategory] = useState<SiteCategory | null>();

  const changeCategory = (categoryName: string) => {
    const category: SiteCategory | undefined = categories?.find(
      (c) => c.name === categoryName
    );
    setCurrentCategory(category);
    setNewSite({ ...newSite, categoryId: category?.id });
  };

  useEffect(() => {
    if (siteId && !siteToEdit) {
      dispatch(setSiteToEdit(siteId));
    } else if (!siteId) {
      dispatch(resetSiteToEdit());
      setNewSite(emptyNewSite);
    } else if (siteToEdit) {
      setCurrentCategory(siteToEdit.category);
      const editData = getSiteEditData(siteToEdit);
      setNewSite(editData);
    }
  }, [dispatch, siteId, siteToEdit]);

  useEffect(() => {
    if (!siteId && chosenCategory && !currentCategory) {
      setCurrentCategory(chosenCategory);
      setNewSite({ ...newSite, categoryId: chosenCategory.id });
    }
  }, [chosenCategory, currentCategory, newSite, siteId]);

  const validateData = (site: NewSite) => {
    const { name, categoryId, location } = site;
    const { city, street } = location.address;
    if (!name || !categoryId || !location.coordinates || !city || !street) {
      dispatch(setNotification("Täytä kaikki kentät"));
      return false;
    }
    return true;
  };

  const setName = (name: string) => {
    const site = { ...newSite, name };
    setNewSite(site);
  };

  const setField = (key: string, value: string) => {
    const fields = { ...newSite.fields, [key]: value };
    const site = { ...newSite, fields };
    setNewSite(site);
  };

  const setAddress = (type: "city" | "postal" | "street", value: string) => {
    const address = { ...newSite.location.address, [type]: value };
    const location = { ...newSite.location, address };
    setNewSite({ ...newSite, location });
  };

  const setCoordinates = (type: "lat" | "lng", value: string) => {
    const updatedSite = updateSiteCoordinates(newSite, type, value);
    setNewSite(updatedSite);
  };

  const send = () => {
    const isValid = validateData(newSite);
    if (siteId && isValid) {
      dispatch(editSite(siteId, newSite));
      dispatch(setNotification("Muokkaus tallennettu"));
    } else if (isValid) {
      dispatch(createSite(newSite));
      dispatch(setNotification("Kohde luotu: " + newSite.name));
      setNewSite({ ...emptyNewSite, categoryId: newSite.categoryId });
    }
  };

  const categoryNames = categories.map((c) => c.name);
  const categoryFields = currentCategory?.fields ?? [];

  return (
    <div className={adminstyles.dataContainer}>
      <div className={adminstyles.titleContainer}>
        <h3>{siteId ? "Muokkaa kohdetta" : "Uusi kohde"}</h3>
      </div>
      <div className={adminstyles.input}>
        <div className={adminstyles.highlightInput}>
          <h1>Kategoria</h1>
          <p>Mihin kategoriaan uusi työkohde kuuluu</p>
          <SelectDropdown
            options={categoryNames}
            handleChange={changeCategory}
            name={currentCategory ? currentCategory.name : "Valitse kategoria"}
          />
        </div>
      </div>
      <div className={adminstyles.input}>
        <p>Nimi</p>
        <input
          onChange={(e) => setName(e.target.value)}
          placeholder="Kohteen nimi"
          value={newSite.name ?? ""}
        />
      </div>
      <div className={adminstyles.input}>
        <p>Osoite</p>
        <input
          onChange={(e) => setAddress("street", e.target.value)}
          placeholder="Katu"
          style={{ marginBottom: "20px" }}
          value={newSite.location.address.street ?? ""}
        />
      </div>
      <div className={adminstyles.input}>
        <div className={adminstyles.halfBlock}>
          <input
            onChange={(e) => setAddress("city", e.target.value)}
            placeholder="Kaupunki"
            value={newSite.location.address.city ?? ""}
          />
          <input
            onChange={(e) => setAddress("postal", e.target.value)}
            placeholder="Postinumero"
            value={newSite.location.address.postal ?? ""}
          />
        </div>
      </div>
      <div className={adminstyles.input}>
        <p>Koordinaatit</p>
        <div className={adminstyles.halfBlock}>
          <input
            onChange={(e) => setCoordinates("lat", e.target.value)}
            placeholder="Lat 25.12345"
            value={newSite?.location.coordinates[0]?.toString() ?? ""}
          />
          <input
            onChange={(e) => setCoordinates("lng", e.target.value)}
            placeholder="Lon 61.12345"
            value={newSite?.location.coordinates[1]?.toString() ?? ""}
          />
        </div>
      </div>
      {categoryFields.map((n: string) => (
        <div key={n} className={adminstyles.input}>
          <p>{n.charAt(0).toUpperCase() + n.slice(1)}</p>
          <input
            onChange={(e) => setField(n, e.target.value)}
            placeholder={`${n}...`}
            value={newSite.fields[n] ?? ""}
          />
        </div>
      ))}
      <div className={adminstyles.input}>
        <button className={adminstyles.button} onClick={send}>
          Tallenna
        </button>
      </div>
    </div>
  );
};
export default CreateSite;
