import React from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "../../styles/misc/admin.module.css";
import { LinkIcon } from "../icons/icons";

const Navbar = () => {
  const { location } = useHistory();
  const isSiteActive = (routePath: string) => {
    return routePath === location.pathname;
  };
  const getClassName = (routePath: string) => {
    return isSiteActive(routePath) ? styles.active : "";
  };
  return (
    <div className={styles.navbar}>
      <div className={getClassName("/admin")}>
        <Link to="/admin">Kaikki kategoriat</Link>
      </div>
      <div className={getClassName("/admin/createcategory")}>
        <Link to="/admin/createcategory">Luo uusi kategoria</Link>
      </div>
      <div className={getClassName("/admin/createsite")}>
        <Link to="/admin/createsite">Luo uusi kohde</Link>
      </div>

      <div className={styles.separator} />

      <div>
        <Link to="/">
          <LinkIcon />
          Etusivu
        </Link>
      </div>
    </div>
  );
};
export default Navbar;
