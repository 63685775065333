import { CSSProperties, useContext } from "react";
import { MapContext, Marker } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { setVisiblePopup } from "../../reducers/mapReducer";
import mapStyles from "../../styles/map.module.css";
import { MarkerFeature, SiteStatus } from "../../types";
import MarkerPopup from "./MarkerPopup";
import {
  MIN_MARKER_HEADER_ZOOM_LEVEL,
  MIN_SATELLITE_ZOOM_LEVEL,
} from "../../utils/mapConstants";

function CustomMarker({ geometry, properties }: MarkerFeature) {
  const dispatch = useDispatch();
  const context = useContext(MapContext);
  const [visiblePopupId]: [string | null] = useSelector(
    (state: { map: { visiblePopupId: string | null } }) => [
      state.map.visiblePopupId,
    ]
  );

  const getBackgroundImage = (status: SiteStatus, blocked: boolean) => {
    if (status === "FINISHED") {
      return "url(/icons/marker-green.svg)";
    } else if (status === "NOT STARTED") {
      return blocked
        ? "url(/icons/marker-red-blocked.svg)"
        : "url(/icons/marker-red.svg)";
    } else {
      return blocked
        ? "url(/icons/marker-yellow-blocked.svg)"
        : "url(/icons/marker-yellow.svg)";
    }
  };

  // Offset by half the width / height
  const markerStyle: CSSProperties = {
    left: -30, // Relative to of marker
    top: -30, // Relative to center of marker
    backgroundImage: getBackgroundImage(properties.status, properties.blocked),
  };

  const alterPopup = (id: string | null) => {
    dispatch(setVisiblePopup(id));
  };

  const zoomLevel = context.viewport?.zoom;
  const [longitude, latitude] = geometry.coordinates;

  const markerHeaderStyle = {
    color:
      zoomLevel && zoomLevel > MIN_SATELLITE_ZOOM_LEVEL ? "white" : "black",
  };

  return (
    <>
      <Marker
        onClick={() => alterPopup(properties.id)}
        longitude={longitude}
        latitude={latitude}
      >
        <div className={mapStyles.marker} style={markerStyle}>
          {zoomLevel && zoomLevel > MIN_MARKER_HEADER_ZOOM_LEVEL && (
            <h3 style={markerHeaderStyle}>{properties.name}</h3>
          )}
        </div>
      </Marker>
      {visiblePopupId === properties.id && (
        <MarkerPopup
          coords={geometry.coordinates}
          properties={properties}
          close={() => alterPopup(null)}
        />
      )}
    </>
  );
}

export default CustomMarker;
