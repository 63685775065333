import React from "react";
import filterStyles from "../../styles/siteList/filters.module.css";

type SelectFilterOptionProps = {
  display: string;
  value: string | null;
  currentValue: string | null;
  isSelectAll?: boolean;
  handleChange: (v: string | null) => void;
};

const SelectFilterOption = ({
  display,
  value,
  currentValue,
  isSelectAll = false,
  handleChange,
}: SelectFilterOptionProps) => {
  const isChosen = display === currentValue;
  let classList = `${isChosen ? filterStyles.selectedOption : ""} `;
  if (isSelectAll) {
    classList += filterStyles.selectAllOption;
  }

  return (
    <div className={classList} onClick={() => handleChange(value)}>
      {display}
    </div>
  );
};

export default SelectFilterOption;
