import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

interface PrivateRouteProps {
  isAuthenticated: boolean;
}

export function PrivateRoute({
  component,
  isAuthenticated,
  ...routeProps
}: PrivateRouteProps & RouteProps) {
  return isAuthenticated ? (
    <Route {...routeProps} />
  ) : (
    <Redirect
      to={{ pathname: "/login", state: { from: routeProps.location } }}
    />
  );
}

export function LoginRoute({
  component,
  isAuthenticated,
  ...routeProps
}: PrivateRouteProps & RouteProps) {
  const routeState = routeProps.location?.state as {
    from: { pathname: string | undefined };
  };
  return !isAuthenticated ? (
    <Route {...routeProps} />
  ) : (
    <Redirect
      to={{
        pathname: routeState.from.pathname ?? "/",
        state: { from: routeProps.location },
      }}
    />
  );
}
