// Contains the map, filters and chosen filters
import React from "react";
import { useSelector } from "react-redux";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import SiteFilters from "../filters/SiteFilters";
import EmbeddedMap from "./EmbeddedMap";
import styles from "../../styles/map.module.css";
import { Site } from "../../types";
import BottomNavbar from "../misc/BottomNavbar";
import { useHistory } from "react-router";

const MapContainer = ({ chosenSite }: { chosenSite?: Site }) => {
  const history = useHistory();
  const [geodata]: [FeatureCollection<Geometry, GeoJsonProperties>] =
    useSelector(
      (state: {
        sites: {
          geodata: FeatureCollection;
        };
      }) => [state.sites.geodata]
    );

  const closeMap = () => {
    if (chosenSite === undefined) return;
    history.push("/sites/");
  };

  const hasChosenSite = chosenSite !== undefined;
  return (
    <div className={styles.mapContainer} onClick={closeMap}>
      {!hasChosenSite && <SiteFilters />}
      <EmbeddedMap chosenSite={chosenSite} data={geodata} />
      <BottomNavbar />
    </div>
  );
};

export default MapContainer;
