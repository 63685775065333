import React from "react";
import { ReactComponent as Next } from "../../assets/next.svg";
import { ReactComponent as Select } from "../../assets/select.svg";
import { ReactComponent as Building } from "../../assets/building.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as Location } from "../../assets/location.svg";
import { ReactComponent as DoubleCheck } from "../../assets/double-check.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Shovel } from "../../assets/shovel.svg";
import { ReactComponent as Info } from "../../assets/info.svg";
import { ReactComponent as Expand } from "../../assets/expand.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as GoBack } from "../../assets/goback.svg";
import { ReactComponent as Send } from "../../assets/send.svg";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Link } from "../../assets/link.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { ReactComponent as Unavailable } from "../../assets/unavailable-2.svg";

const LargeNextIcon = () => {
  return <Next />;
};

const SelectIcon = () => {
  return <Select />;
};

const BuildingIcon = () => {
  return <Building />;
};

const SearchIcon = () => {
  return <Search />;
};

const LocationIcon = () => {
  return <Location />;
};

const DoubleCheckIcon = () => {
  return <DoubleCheck />;
};

const CheckIcon = () => {
  return <Check />;
};

const CrossIcon = () => {
  return <Cross />;
};

const ClockIcon = () => {
  return <Clock />;
};

const ShovelIcon = () => {
  return <Shovel />;
};

const InfoIcon = () => {
  return <Info />;
};

const ExpandIcon = () => {
  return <Expand />;
};

const GoBackIcon = () => {
  return <GoBack />;
};

const SendIcon = () => {
  return <Send />;
};

const PlusIcon = () => {
  return <Plus />;
};

const LinkIcon = () => {
  return <Link />;
};

const TrashIcon = () => {
  return <Trash />;
};

const MenuIcon = () => {
  return <Menu />;
};

const UnavailableIcon = () => {
  return <Unavailable />;
};

export {
  LargeNextIcon,
  SelectIcon,
  BuildingIcon,
  SearchIcon,
  LocationIcon,
  DoubleCheckIcon,
  CheckIcon,
  CrossIcon,
  ClockIcon,
  ShovelIcon,
  InfoIcon,
  ExpandIcon,
  GoBackIcon,
  SendIcon,
  PlusIcon,
  LinkIcon,
  TrashIcon,
  MenuIcon,
  UnavailableIcon,
};
