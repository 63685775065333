import React, { useEffect } from "react";
import Navbar from "./Navbar";
import styles from "../../styles/misc/admin.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../reducers/adminReducer";
import Categories from "./Categories";
import { Site, SiteCategory } from "../../types";
import Sites from "./Sites";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateCategory from "./CreateCategory";
import CreateSite from "./CreateSite";

const AdminFrontPage = () => {
  const dispatch = useDispatch();

  const [chosenCategory, sites, categories]: [
    SiteCategory,
    Site[],
    SiteCategory[]
  ] = useSelector(
    (state: {
      admin: {
        chosenCategory: SiteCategory;
        sites: Site[];
        categories: SiteCategory[];
      };
    }) => [
      state.admin.chosenCategory,
      state.admin.sites,
      state.admin.categories,
    ]
  );

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories());
    }
  }, [categories, dispatch]);

  const { path } = useRouteMatch();
  return (
    <div className={styles.adminContainer}>
      <Navbar />
      <Switch>
        <Route exact path={path}>
          <Categories />
        </Route>
        <Route path="/admin/createcategory">
          <CreateCategory />
        </Route>
        <Route path="/admin/createsite">
          <CreateSite chosenCategory={chosenCategory} />
        </Route>
        <Route
          path="/admin/editsite/:siteId"
          render={({ match }) => (
            <CreateSite
              siteId={match.params.siteId}
              sites={sites}
              chosenCategory={chosenCategory}
            />
          )}
        />
        <Route
          path={`${path}/:categoryId`}
          render={({ match }) => (
            <Sites
              categoryId={match.params.categoryId}
              sites={sites}
              category={chosenCategory}
            />
          )}
        />
      </Switch>
    </div>
  );
};
export default AdminFrontPage;
