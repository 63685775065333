import React from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/notifications.module.css";

const Login = () => {
  const text: string | null = useSelector(
    (state: { notification: { text: string | null } }) =>
      state.notification.text
  );

  if (!text) {
    return <></>;
  }

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.notification}>
        <p>{text}</p>
      </div>
    </div>
  );
};
export default Login;
