import React, { useEffect, useState } from "react";
import popupStyles from "../../styles/misc/popup.module.css";

interface PopupProps {
  callback: (arg: string) => void;
}

const InputPopup = ({ callback }: PopupProps) => {
  const [name, setName] = useState("");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className={popupStyles.popupBackground}>
      <div className={popupStyles.popupContainer}>
        <p>Kuka on kuskina?</p>
        <input
          placeholder="Kirjoita nimesi..."
          onChange={(e) => setName(e.target.value)}
          className={popupStyles.textField}
        ></input>
        <br />
        <button
          onClick={() => callback(name)}
          className={popupStyles.fullButton}
        >
          Lisää nimi
        </button>
      </div>
    </div>
  );
};
export default InputPopup;
