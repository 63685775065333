import React, { useRef, useState } from "react";
import filterStyles from "../../styles/siteList/filters.module.css";

type SelectDropdownProps = {
  name: string;
  options: string[];
  handleChange: (v: string) => void;
};

const SelectDropdown = ({
  name,
  options,
  handleChange,
}: SelectDropdownProps) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const optionsClasses = `${filterStyles.options} ${
    optionsOpen ? filterStyles.open : filterStyles.closed
  }`;
  const filterClasses = `${filterStyles.filter} ${
    optionsOpen ? filterStyles.openFilter : null
  }`;

  return (
    <div
      ref={filterRef}
      className={filterClasses}
      style={{ width: "100%", backgroundColor: "#ffffff" }}
      onClick={() => setOptionsOpen(!optionsOpen)}
    >
      <div ref={inputRef} className={filterStyles.typeSelect}>
        <div className={filterStyles.text}>
          <p className={filterStyles.chosenName}>{name}</p>
        </div>
        <input hidden />
      </div>
      <div className={optionsClasses}>
        {options.map((o: string, index: number) => (
          <div key={index} onClick={() => handleChange(o)}>
            {o}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectDropdown;
