import React, { useRef } from "react";
import filterStyles from "../../styles/siteList/filters.module.css";
import useClickyOptions from "../../hooks/useClickOptions";
import { KeyValueFilterOption } from "../../types";
import SelectFilterOption from "./SelectFilterOption";

type SelectFilterProps = {
  name: string;
  options: KeyValueFilterOption[];
  currentValue: string | null;
  hasSelectAll?: boolean;
  handleChange: (v: string | null) => void;
};

const SelectFilter = ({
  name,
  options,
  currentValue,
  hasSelectAll,
  handleChange,
}: SelectFilterProps) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const [optionsOpen] = useClickyOptions(filterRef, inputRef);
  const optionsClasses = `${filterStyles.options} ${
    optionsOpen && filterStyles.open
  }`;
  const filterClasses = `${filterStyles.filter} ${
    optionsOpen ? filterStyles.openFilter : null
  }`;

  return (
    <div ref={filterRef} className={filterClasses}>
      <div ref={inputRef} className={filterStyles.selectInput}>
        <p className={filterStyles.filterLabel}>{name}</p>
        <p className={filterStyles.filterValue}>{currentValue}</p>
        <input hidden />
      </div>
      <div className={optionsClasses}>
        {hasSelectAll && (
          <SelectFilterOption
            display="Kaikki"
            value="Kaikki"
            isSelectAll={true}
            currentValue={currentValue}
            handleChange={() => handleChange(null)}
          />
        )}
        {options.map((o) => (
          <SelectFilterOption
            key={o.value}
            display={o.display}
            value={o.value}
            currentValue={currentValue}
            handleChange={() => handleChange(o.value)}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectFilter;
