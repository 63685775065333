import { NewSite, Site } from "../types";

export const isCacheExpired = (saveDate: number | undefined) => {
  if (!saveDate) return true;
  const now = Date.now();
  const diff = now - saveDate;
  const expired = diff > 12 * 60 * 60 * 1000;
  return expired;
};

export const emptyNewSite = {
  location: {
    address: {
      street: undefined,
      city: undefined,
      postal: undefined,
    },
    coordinates: ["", ""] as [string, string],
  },
  fields: {},
};

export const getSiteEditData = (site: Site) => {
  const { location, fields, name } = site;
  const categoryId = site.category.id;
  const stringifiedLocation = {
    ...location,
    coordinates: location.coordinates.map((s) => s.toString()) as [
      string,
      string
    ],
  };
  const siteData: NewSite = {
    name,
    location: stringifiedLocation,
    fields,
    categoryId,
  };
  return siteData;
};

export const updateSiteCoordinates = (
  site: NewSite,
  type: "lat" | "lng",
  value: string
) => {
  const newCoordinates = [...site.location.coordinates] as [string, string];
  if (type === "lat") {
    newCoordinates[0] = value;
  } else {
    newCoordinates[1] = value;
  }
  const newLocation = { ...site.location, coordinates: newCoordinates };
  return { ...site, location: newLocation };
};

export const isValidCoordinates = (coords: [string, string]) => {
  const c1 = parseFloat(coords[0]);
  const c2 = parseFloat(coords[0]);
  return !(c1 || c2);
};
