import { DispatchFunction, ReducerAction } from "../types";

const initialState: { text: string | null } = {
  text: null,
};

export const reducer = (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case "SET_NOTIFICATION": {
      const updatedState = { ...state, text: action.data };
      return updatedState;
    }
    case "CLEAR_NOTIFICATION": {
      const updatedState = { ...state, text: null };
      return updatedState;
    }
    default:
      return state;
  }
};

export const setNotification =
  (text: string) => async (dispatch: DispatchFunction) => {
    const clear = () =>
      dispatch({
        type: "CLEAR_NOTIFICATION",
      });
    dispatch({
      type: "SET_NOTIFICATION",
      data: text,
    });
    const notifTime = 4000;
    setTimeout(clear, notifTime);
  };

export default reducer;
