import { Site } from "../types";

export const getAllPhasesForSite = (site: Site) => {
  return site.category.phases;
};

const getPhaseIndex = (phase: string, allPhases: string[]) => {
  return allPhases.findIndex((p) => p === phase);
};

export const getPhaseDescription = (phase: string, allPhases: string[]) => {
  const currentPhaseIdx = getPhaseIndex(phase, allPhases);
  if (allPhases[currentPhaseIdx - 1] === phase) {
    return "Edellinen vaihe";
  }
  if (allPhases[currentPhaseIdx] === phase) {
    return "Työn alla";
  }
  if (allPhases[currentPhaseIdx + 1] === phase) {
    return "Seuraava vaihe";
  }
};

export const getPhaseActionName = (site: Site, phase: string) => {
  const allPhases = getAllPhasesForSite(site);
  const currentPhaseIdx = getPhaseIndex(phase, allPhases);
  const isReady = currentPhaseIdx === allPhases.length - 1;
  if (isReady) {
    return;
  }
  if (allPhases[currentPhaseIdx - 1] === phase) {
    return "Palaa edelliseen";
  }
  if (allPhases[currentPhaseIdx] === phase) {
    return "Merkkaa suoritetuksi";
  }
};

export const getPhaseRenderStatus = (site: Site, phase: string) => {
  const allPhases = getAllPhasesForSite(site);
  const isCurrent = phase === site.phase;
  const phaseIdx = getPhaseIndex(phase, allPhases);
  const currentIdx = getPhaseIndex(site.phase, allPhases);
  const isPrev = phaseIdx === currentIdx - 1;
  const isNext = phaseIdx === currentIdx + 1;
  const isCurrentFirst = currentIdx === 0;
  const isCurrentLast = currentIdx === allPhases.length - 1;
  const showTwoNext = isCurrentFirst && phaseIdx === currentIdx + 2;
  const showTwoPrev = isCurrentLast && phaseIdx === currentIdx - 2;
  const phaseNumber = phaseIdx + 1;
  let status;
  if (isCurrent && isCurrentLast) {
    status = "allFinished";
  } else if (isCurrent) {
    status = "current";
  } else if (isPrev) {
    status = "previous";
  } else if (isNext) {
    status = "next";
  } else if (showTwoNext) {
    status = "twoNext";
  } else if (showTwoPrev) {
    status = "twoPrev";
  }
  return { status, phaseNumber };
};
