import React, { useState } from "react";
import adminstyles from "../../styles/misc/admin.module.css";
import { createCategory } from "../../reducers/adminReducer";
import { useDispatch } from "react-redux";
import { NewSiteCategory } from "../../types";
import { setNotification } from "../../reducers/notificationReducer";
import FieldInput from "./inputs/FieldInput";
import PhaseInput from "./inputs/PhaseInput";

const CreateCategory = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [fields, setFields] = useState<string[]>([]);
  const [phases, setPhases] = useState<string[]>([]);

  const send = () => {
    const category: NewSiteCategory = {
      name,
      fields,
      phases,
    };
    if (name.length > 0 && phases.length > 0) {
      dispatch(createCategory(category));
      dispatch(setNotification("Kategoria luotu: " + category.name));
      setName("");
      setFields([]);
      setPhases([]);
      return;
    }
    dispatch(setNotification("Lisää vähintään nimi ja työvaiheet"));
  };

  return (
    <div className={adminstyles.dataContainer}>
      <div className={adminstyles.titleContainer}>
        <h3>Uusi kategoria</h3>
      </div>
      <div className={adminstyles.input}>
        <p>Nimi</p>
        <input
          onChange={(e) => setName(e.target.value)}
          placeholder="Kategorian nimi"
          value={name}
        />
      </div>
      <FieldInput fields={fields} saveFields={setFields} />
      <PhaseInput phases={phases} savePhases={setPhases} />
      <div className={adminstyles.input}>
        <button className={adminstyles.button} onClick={() => send()}>
          Tallenna
        </button>
      </div>
    </div>
  );
};

export default CreateCategory;
