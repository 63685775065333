import { DispatchFunction, ReducerAction } from "../types";

type MapState = {
  visiblePopupId: string | null;
  isExpanded: boolean;
};

const initialState: MapState = {
  visiblePopupId: null,
  isExpanded: false,
};

const reducer = (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case "SET_POPUP": {
      const popupId = action.data;
      return { ...state, visiblePopupId: popupId };
    }
    case "SET_MAP_EXPANDED": {
      const isExpanded = action.data;
      return { ...state, isExpanded };
    }
    default:
      return state;
  }
};

export const setVisiblePopup =
  (popupId: string | null) => async (dispatch: DispatchFunction) => {
    dispatch({
      type: "SET_POPUP",
      data: popupId,
    });
  };

export const setMapExpanded =
  (isExpanded: boolean) => async (dispatch: DispatchFunction) => {
    dispatch({
      type: "SET_MAP_EXPANDED",
      data: isExpanded,
    });
  };

export default reducer;
