import React, { useState } from "react";
import InputPopup from "../components/sites/InputPopup";
import ConfirmPopup from "../components/sites/ConfirmPopup";
import { PopupModalProps } from "../types";
import { useDispatch } from "react-redux";
import { setUserName } from "../reducers/authReducer";

const usePopupModal = () => {
  const [popup, setPopup] = useState<JSX.Element | null>(null);
  const dispatch = useDispatch();

  const setGetNamePopup = (callback: (arg: string) => void) => {
    const callbackWithNulling = (arg: string) => {
      setPopup(null);
      callback(arg);
      dispatch(setUserName(arg));
    };
    setPopup(<InputPopup callback={callbackWithNulling} />);
  };

  const setPhaseChangePopup = (
    confirmText: string,
    callback: (arg: boolean) => void
  ) => {
    const callbackWithNulling = (confirmed: boolean) => {
      setPopup(null);
      callback(confirmed);
    };
    setPopup(
      <ConfirmPopup confirmText={confirmText} callback={callbackWithNulling} />
    );
  };

  const setConfirmPhaseIncrementPopup = (callback: (arg: boolean) => void) => {
    return setPhaseChangePopup(
      "Haluatko varmasti siirtää kohteen seuraavaan vaiheeseen?",
      callback
    );
  };

  const setConfirmPhaseDecrementPopup = (callback: (arg: boolean) => void) => {
    return setPhaseChangePopup(
      "Haluatko varmasti palauttaa kohteen edelliseen vaiheeseen?",
      callback
    );
  };

  return [
    popup,
    setGetNamePopup,
    setConfirmPhaseIncrementPopup,
    setConfirmPhaseDecrementPopup,
  ] as PopupModalProps;
};

export default usePopupModal;
