import React from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import { InfoIcon, ShovelIcon } from "../../icons/icons";

interface NavProps {
  setPhaseTabActive: (isActive: boolean) => void;
  isPhaseActive: boolean;
}

const SiteNav = ({ setPhaseTabActive, isPhaseActive }: NavProps) => {
  const goToPhase = () => {
    setPhaseTabActive(true);
  };
  const goToInfo = () => {
    setPhaseTabActive(false);
  };
  return (
    <div className={phaseStyles.tabButtons}>
      <button
        onClick={goToPhase}
        className={
          isPhaseActive
            ? `${phaseStyles.active} ${phaseStyles.left}`
            : phaseStyles.left
        }
      >
        <span>
          <ShovelIcon />
        </span>
        Kohteen tilanne
      </button>
      <button
        onClick={goToInfo}
        className={
          isPhaseActive
            ? phaseStyles.right
            : `${phaseStyles.active} ${phaseStyles.right}`
        }
      >
        <span>
          <InfoIcon />
        </span>
        Tiedot
      </button>
    </div>
  );
};
export default SiteNav;
