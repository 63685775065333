import React, { useState } from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import adminstyles from "../../../styles/misc/admin.module.css";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../reducers/notificationReducer";
import { CrossIcon } from "../../icons/icons";

type Props = {
  phases: string[];
  savePhases: (arg: string[]) => void;
};

const PhaseInput = ({ phases, savePhases }: Props) => {
  const dispatch = useDispatch();
  const [currentPhase, setCurrentPhase] = useState("");

  const isPhaseValid = (phaseName: string) => {
    const isValid = !phases.includes(phaseName);
    if (!isValid) {
      dispatch(setNotification(`Vaihe "${phaseName}" on jo lisätty`));
    }
    return isValid;
  };

  const addPhase = () => {
    if (currentPhase.length > 0) {
      if (!isPhaseValid(currentPhase)) return;
      const newPhases = [...phases, currentPhase];
      savePhases(newPhases);
      setCurrentPhase("");
    } else {
      dispatch(setNotification("Lisää vaiheelle nimi"));
    }
  };

  const removePhase = (fieldName: string) => {
    savePhases(phases.filter((f: string) => f !== fieldName));
  };

  return (
    <div className={adminstyles.listInput}>
      <p>Työvaiheet järjestyksessä</p>
      <div className={phaseStyles.textAreaContainer}>
        <textarea
          className={phaseStyles.textArea}
          placeholder="Vaiheen nimi"
          onChange={(e) => setCurrentPhase(e.target.value)}
          value={currentPhase}
        />
        <button onClick={addPhase} className={adminstyles.icon}>
          +
        </button>
      </div>
      {phases.map((p: string, index: number) => (
        <div key={p} className={adminstyles.listItem}>
          <h3>
            {index + 1}. {p}
          </h3>
          <div onClick={() => removePhase(p)}>
            <CrossIcon />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhaseInput;
