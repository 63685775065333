import React, { useState } from "react";
import styles from "../../styles/misc/admin.module.css";
import { LargeNextIcon, TrashIcon } from "../icons/icons";
import { SiteCategory } from "../../types";
import { useDispatch } from "react-redux";
import { setChosenCategory } from "../../reducers/adminReducer";
import { Link, useRouteMatch } from "react-router-dom";
import ConfirmPopup from "../sites/ConfirmPopup";

type Props = {
  category: SiteCategory;
  deleteCategory: () => void;
};

const CategoryListTile = ({ category, deleteCategory }: Props) => {
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const handleClick = () => {
    dispatch(setChosenCategory(category));
  };

  const { url } = useRouteMatch();

  const handleCallback = (confirmed: boolean) => {
    if (confirmed) {
      deleteCategory();
    }
    setPopupOpen(false);
  };

  const getConfirmText = () => {
    const txt = "Haluatko varmasti poistaa kategorian ";
    return txt + category.name + "?";
  };

  const getPluralSiteCount = (count: number | undefined) => {
    if (count === undefined) return "Ei kohteita";
    return count === 1 ? "kohde" : "kohdetta";
  };

  return (
    <div>
      {popupOpen ? (
        <ConfirmPopup
          callback={handleCallback}
          confirmText={getConfirmText()}
        />
      ) : (
        <div />
      )}
      <div className={styles.tile}>
        <p className={styles.header}>{category.name}</p>
        <p>
          {category.siteCount} {getPluralSiteCount(category.siteCount)}
        </p>
        <button
          onClick={() => setPopupOpen(!popupOpen)}
          className={styles.deleteButton}
        >
          <TrashIcon />
        </button>
        <Link to={`${url}/${category.id}`}>
          <button onClick={handleClick}>
            <LargeNextIcon />
          </button>
        </Link>
      </div>
    </div>
  );
};
export default CategoryListTile;
