import { FeatureCollection } from "geojson";
import siteService from "../services/sites";
import {
  DispatchFunction,
  FilterActionData,
  FilterState,
  Site,
  ReducerAction,
  SiteActionData,
  PhaseUpdateEventType,
  NewComment,
  FilterOptions,
} from "../types";

type SiteState = {
  geodata?: FeatureCollection;
  chosenSite?: Site;
  filters: FilterState;
  filterOptions: FilterOptions;
};

const initialState: SiteState = {
  filters: {
    category: null,
    city: null,
    phase: null,
    query: null,
  },
  filterOptions: {
    categories: [],
    cities: [],
    phases: [],
  },
};

const reducer = (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case "APPLY_FILTERS": {
      const data = action.data as FilterActionData;
      const { geodata, filters, filterOptions } = data;
      return { ...state, filters, geodata, filterOptions };
    }
    case "UPDATE_SITE": {
      const site = action.data as Site;
      return { ...state, chosenSite: { ...site } };
    }
    case "SELECT_SITE": {
      const site = action.data as Site;
      return { ...state, chosenSite: { ...site } };
    }
    case "CLEAR_CHOSEN_SITE": {
      return { ...state, chosenSite: undefined };
    }
    case "ADD_COMMENT": {
      const site = action.data as Site;
      return { ...state, chosenSite: { ...site } };
    }
    case "SET_FILTER_OPTIONS": {
      const options = action.data as FilterOptions;
      return { ...state, filterOptions: options };
    }
    default:
      return state;
  }
};

export const initSites = () => async (dispatch: DispatchFunction) => {
  const { geodata, filterOptions, defaultCategory } =
    (await siteService.getSites()) as SiteActionData;
  dispatch({
    type: "APPLY_FILTERS",
    data: {
      geodata,
      filterOptions,
      filters: { category: defaultCategory },
    },
  });
};

export const applyFilters =
  (newFilters: FilterState) => async (dispatch: DispatchFunction) => {
    const { geodata, filterOptions } = (await siteService.getSites(
      newFilters
    )) as SiteActionData;
    dispatch({
      type: "APPLY_FILTERS",
      data: { geodata, filters: newFilters, filterOptions },
    });
  };

export const getSite = (id: string) => async (dispatch: DispatchFunction) => {
  const site = await siteService.getSite(id);
  dispatch({
    type: "SELECT_SITE",
    data: site,
  });
};

export const clearChosenSite = () => async (dispatch: DispatchFunction) => {
  dispatch({
    type: "CLEAR_CHOSEN_SITE",
  });
};

export const updatePhase =
  (siteId: string, editType: PhaseUpdateEventType, sender: string) =>
  async (dispatch: any, getState: () => { sites: SiteState }) => {
    const currentState = getState();
    const data = { editType, sender };
    const site = await siteService.updatePhase(siteId, data);
    dispatch({
      type: "UPDATE_SITE",
      data: site,
    });
    dispatch(applyFilters(currentState.sites.filters));
  };

export const addComment =
  (id: string, data: NewComment) => async (dispatch: DispatchFunction) => {
    const site = await siteService.addComment(id, data);
    dispatch({
      type: "ADD_COMMENT",
      data: site,
    });
  };

export const setBlocked =
  (id: string, blocked: boolean) =>
  async (dispatch: any, getState: () => { sites: SiteState }) => {
    const site = await siteService.setBlocked(id, blocked);
    dispatch({
      type: "UPDATE_SITE",
      data: site,
    });
    const currentState = getState();
    dispatch(applyFilters(currentState.sites.filters));
  };

export default reducer;
