import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getSite,
  addComment,
  updatePhase,
  setBlocked,
} from "../../../reducers/siteReducer";
import { useLocation } from "react-router-dom";
import {
  Site,
  NewComment,
  UserData,
  PopupModalProps,
  PhaseUpdateEventType,
} from "../../../types";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import SiteComments from "./SiteComments";
import SiteInfo from "./SiteInfo";
import SiteNav from "./SiteNav";
import { setVisiblePopup } from "../../../reducers/mapReducer";
import usePopupModal from "../../../hooks/usePopupModal";
import { renderPhase } from "../../../utils/renderUtils";
import { getAllPhasesForSite } from "../../../utils/phases";
import MarkBlockedButton from "../../misc/MarkBlockedButton";
import SiteBlockedText from "../../misc/SiteBlockedText";

const SiteInspect = () => {
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const siteId: string | undefined = urlLocation.pathname.split("/").pop();
  const [site, currentUser]: [Site, UserData] = useSelector(
    (state: {
      sites: { chosenSite: Site };
      auth: { currentUser: UserData };
    }) => [state.sites.chosenSite, state.auth.currentUser]
  );
  const [phaseTabActive, setPhaseTabActive] = useState(true);
  const [
    popup,
    askNamePopup,
    confirmIncrementPopup,
    confirmDecrementPopup,
  ]: PopupModalProps = usePopupModal();

  useEffect(() => {
    if (siteId) {
      dispatch(getSite(siteId));
      dispatch(setVisiblePopup(null));
    }
  }, [dispatch, siteId]);

  const editPhase = (action: PhaseUpdateEventType) => (confirmed: boolean) => {
    const callDispatch = (sender: string) => {
      dispatch(updatePhase(site.id, action, sender));
    };
    if (confirmed) {
      if (!currentUser.name) askNamePopup(callDispatch);
      else callDispatch(currentUser.name);
    }
  };

  const confirmIncrementPhase = () => {
    confirmIncrementPopup(editPhase("increment"));
  };

  const confirmDecrementPhase = () => {
    confirmDecrementPopup(editPhase("decrement"));
  };

  const sendComment = (content: string) => {
    const send = (sender: string) => {
      const newComment: NewComment = {
        content,
        sender,
      };
      dispatch(addComment(site.id, newComment));
    };
    if (!currentUser.name) askNamePopup(send);
    else send(currentUser.name);
  };

  const toggleBlocked = () => {
    dispatch(setBlocked(site.id, !site.blocked));
  };

  if (site === undefined) return <></>;

  const sitePhases = getAllPhasesForSite(site);
  return (
    <div className={phaseStyles.siteContainer}>
      {popup}
      <div className={phaseStyles.mapContainer} />
      <div className={phaseStyles.dataContainer}>
        <SiteNav
          isPhaseActive={phaseTabActive}
          setPhaseTabActive={setPhaseTabActive}
        />
        {phaseTabActive ? (
          <>
            <div className={phaseStyles.phaseContainer}>
              {sitePhases.map((phase: string) =>
                renderPhase(
                  site,
                  phase,
                  confirmIncrementPhase,
                  confirmDecrementPhase
                )
              )}
            </div>

            {site.blocked ? (
              <SiteBlockedText toggle={toggleBlocked} />
            ) : (
              <MarkBlockedButton toggle={toggleBlocked} />
            )}

            <SiteComments comments={site.comments} sendNew={sendComment} />
          </>
        ) : (
          <SiteInfo site={site} />
        )}
      </div>
    </div>
  );
};
export default SiteInspect;
