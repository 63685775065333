import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyFilters } from "../../reducers/siteReducer";
import filterStyles from "../../styles/siteList/filters.module.css";
import { FilterKeys, FilterOptions, FilterState } from "../../types";
import SearchFilter from "./SearchFilter";
import SelectFilter from "./SelectFilter";

const SiteFilters = () => {
  const dispatch = useDispatch();
  const [filters, filterOptions]: [FilterState, FilterOptions] = useSelector(
    (state: {
      sites: { filters: FilterState; filterOptions: FilterOptions };
    }) => [state.sites.filters, state.sites.filterOptions]
  );

  const hasCategoryChanged = (
    oldFilters: FilterState,
    newFilters: FilterState
  ) => {
    return oldFilters.category !== newFilters.category;
  };

  const initialFilters = {
    city: null,
    phase: null,
    category: null,
    query: null,
  };

  const updateFilterState = (filterKey: string, val: string | null) => {
    const newFilters = { ...filters, [filterKey]: val };
    const finalFilters = hasCategoryChanged(filters, newFilters)
      ? { ...initialFilters, category: newFilters.category }
      : newFilters;
    dispatch(applyFilters(finalFilters));
  };

  const updateFilterProp = (filterKey: string) => (val: string | null) => {
    updateFilterState(filterKey, val);
  };

  const getFilterCurrentDisplayValue = (
    filterKey: FilterKeys,
    currentValue: string | null
  ) => {
    const defaultValue = "Kaikki";
    if (!currentValue) {
      return defaultValue;
    }
    const options = filterOptions[filterKey];
    const item = options.find(({ value }) => value === currentValue);
    return item ? item.display : defaultValue;
  };

  return (
    <div className={filterStyles.filterContainer}>
      <div className={filterStyles.filterBlock}>
        <SelectFilter
          handleChange={updateFilterProp("category")}
          options={filterOptions.categories}
          currentValue={getFilterCurrentDisplayValue(
            "categories",
            filters.category
          )}
          name="Kategoria"
        />
        <SelectFilter
          handleChange={updateFilterProp("city")}
          options={filterOptions.cities}
          currentValue={getFilterCurrentDisplayValue("cities", filters.city)}
          hasSelectAll={true}
          name="Kaupunki"
        />
        <SelectFilter
          handleChange={updateFilterProp("phase")}
          options={filterOptions.phases}
          currentValue={getFilterCurrentDisplayValue("phases", filters.phase)}
          hasSelectAll={true}
          name="Vaihe"
        />
        <SearchFilter
          handleChange={updateFilterProp("query")}
          currentValue={filters.query}
          placeholder="Hae nimellä"
        />
      </div>
    </div>
  );
};

export default SiteFilters;
