import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useClickyOptions from "../../hooks/useClickOptions";
import { logout } from "../../reducers/authReducer";
import { setNotification } from "../../reducers/notificationReducer";
import styles from "../../styles/misc/navbar.module.css";
import { UserData } from "../../types";
import { MenuIcon } from "../icons/icons";

const BottomNavbar = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const [optionsOpen] = useClickyOptions(wrapperRef, listRef);
  const optionsClasses = `${styles.menu} ${
    optionsOpen ? styles.open : styles.closed
  }`;
  const filterClasses = `${styles.filter} ${
    optionsOpen ? styles.openFilter : null
  }`;

  const [currentUser]: [UserData] = useSelector(
    (state: {
      auth: {
        currentUser: UserData;
      };
    }) => [state.auth.currentUser]
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const logUserOut = () => {
    localStorage.clear();
    setTimeout(() => dispatch(logout()), 300);
  };

  const directToAdmin = () => {
    if (!currentUser.isAdmin) {
      dispatch(setNotification("Hallinnointi vaatii admin-käyttäjän"));
    } else {
      history.push("/admin");
    }
  };

  return (
    <div ref={wrapperRef}>
      <div className={filterClasses}>
        <MenuIcon />
      </div>
      <div className={optionsClasses}>
        <div>
          <button onClick={directToAdmin}>Hallinnoi</button>
        </div>
        <div>
          <button onClick={logUserOut}>Kirjaudu ulos</button>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
