import React from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";

interface PhaseProps {
  phase: string;
  phaseNumber: number;
}

const AllFinishedPhase = ({ phase, phaseNumber }: PhaseProps) => {
  return (
    <div
      className={`${phaseStyles.phase} ${phaseStyles.activePhase} ${phaseStyles.allCompletedPhase}`}
    >
      <div>
        <p className={phaseStyles.description}>Nykyinen vaihe</p>
        <p className={phaseStyles.name}>
          {phaseNumber}. {phase}
        </p>
      </div>
    </div>
  );
};
export default AllFinishedPhase;
