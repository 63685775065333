import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { LngLatBounds } from "mapbox-gl";
import CustomMarker from "../components/map/CustomMarker";
import ActivePhase from "../components/sites/inspect/ActivePhase";
import AllFinishedPhase from "../components/sites/inspect/AllFinishedPhase";
import NextPhase from "../components/sites/inspect/NextPhase";
import NormalPhase from "../components/sites/inspect/NormalPhase";
import { FeatureGeometry, MarkerProperties, Site } from "../types";
import { getPhaseRenderStatus } from "./phases";

export const getCustomMarkerArray = (
  features: Feature<Geometry, GeoJsonProperties>[],
  bounds: LngLatBounds | null
) => {
  const visibleFeatures = bounds
    ? features.filter((f) => {
        const geom = f.geometry as FeatureGeometry;
        const coordinates = geom.coordinates;
        const latlng = { lng: coordinates[0], lat: coordinates[1] };
        return bounds.contains(latlng);
      })
    : features;
  return visibleFeatures.map((marker) => {
    const props = {
      properties: marker.properties as MarkerProperties,
      geometry: marker.geometry as FeatureGeometry,
    };
    return <CustomMarker key={props.properties.id} {...props} />;
  });
};

export const getCurrentPhase = (
  phase: string,
  n: number,
  confirmIncrement: () => void
) => {
  return (
    <ActivePhase
      key={phase}
      phase={phase}
      phaseNumber={n}
      markCompleted={confirmIncrement}
    />
  );
};

export const getDefaultPhase = (
  site: Site,
  phase: string,
  n: number,
  showDescription = false,
  showGoBack = false,
  confirmDecrement?: () => void
) => {
  return (
    <NormalPhase
      key={phase}
      phase={phase}
      phaseNumber={n}
      description={showDescription ? site.phaseStatus[phase] : undefined}
      goBack={showGoBack ? confirmDecrement : undefined}
    />
  );
};

export const renderPhase = (
  site: Site,
  phase: string,
  confirmIncrement: () => void,
  confirmDecrement: () => void
) => {
  const { status, phaseNumber } = getPhaseRenderStatus(site, phase);
  switch (status) {
    case "current": {
      return getCurrentPhase(phase, phaseNumber, confirmIncrement);
    }
    case "next": {
      return <NextPhase key={phase} phase={phase} phaseNumber={phaseNumber} />;
    }
    case "previous": {
      return getDefaultPhase(
        site,
        phase,
        phaseNumber,
        true,
        true,
        confirmDecrement
      );
    }
    case "twoNext": {
      return getDefaultPhase(site, phase, phaseNumber);
    }
    case "twoPrev": {
      return getDefaultPhase(site, phase, phaseNumber, true);
    }
    case "allFinished": {
      return (
        <AllFinishedPhase key={phase} phase={phase} phaseNumber={phaseNumber} />
      );
    }
    default: {
      return;
    }
  }
};
