import React from "react";
import { Site } from "../../../types";
import styles from "../../../styles/siteInspect/info.module.css";

const SiteInfo = ({ site }: { site: Site }) => {
  return (
    <div className={styles.siteInfo}>
      <div>
        <p className={styles.description}>Kohteen nimi</p>
        <p>{site.name}</p>
      </div>
      <div>
        <p className={styles.description}>Kategoria</p>
        <p>{site.category.name}</p>
      </div>
      <div>
        <p className={styles.description}>Osoite</p>
        <p>{site.location.address.street}</p>
      </div>
      <div>
        <p className={styles.description}>Kaupunki</p>
        <p>{site.location.address.city}</p>
      </div>
      <div>
        <p className={styles.description}>Postinumero</p>
        <p>{site.location.address.postal}</p>
      </div>
      {Object.entries(site.fields).map(([key, value]) => (
        <div key={key}>
          <p className={styles.description}>{key}</p>
          <p>{value}</p>
        </div>
      ))}
    </div>
  );
};
export default SiteInfo;
