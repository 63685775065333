import { DispatchFunction, ReducerAction, UserData } from "../types";
import loginService from "../services/login";
import siteService from "../services/sites";
import { setNotification } from "./notificationReducer";
import { isCacheExpired } from "../utils/misc";

type AuthState = {
  currentUser: UserData | null;
  isLoading: boolean;
};

const initialState: AuthState = {
  currentUser: null,
  isLoading: false,
};

export const reducer = (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case "SET_USER": {
      const user = action.data;
      const updatedState = { ...state, currentUser: user };
      return updatedState;
    }
    case "SET_USER_NAME": {
      const newUser = { ...state.currentUser, name: action.data };
      const updatedState = { ...state, currentUser: newUser };
      return updatedState;
    }
    case "LOGOUT": {
      const updatedState = { ...state, currentUser: null };
      return updatedState;
    }
    case "SET_LOADING": {
      const updatedState = { ...state, isLoading: action.data };
      return updatedState;
    }
    default:
      return state;
  }
};

export const setUserName =
  (name: string) => async (dispatch: DispatchFunction) => {
    const cachedUser = localStorage.getItem("currentUser");
    if (cachedUser) {
      const user = JSON.parse(cachedUser);
      localStorage.setItem("currentUser", JSON.stringify({ ...user, name }));
    }

    dispatch({
      type: "SET_USER_NAME",
      data: name,
    });
  };

export const login =
  (credentials: { password: string }) => async (dispatch: any) => {
    try {
      const userData = (await loginService.login(credentials)) as UserData;
      const currentUser = { ...userData, saveTime: Date.now() };
      siteService.setToken(currentUser as UserData);
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      dispatch({
        type: "SET_USER",
        data: currentUser,
      });
    } catch {
      dispatch(setNotification("Väärä salasana"));
    }
    const finishLoading = () => {
      dispatch({
        type: "SET_LOADING",
        data: false,
      });
    };
    setTimeout(finishLoading, 500);
  };

export const setLoading =
  (isLoading: boolean) => async (dispatch: DispatchFunction) => {
    dispatch({
      type: "SET_LOADING",
      data: isLoading,
    });
  };

export const initialLogin = () => async (dispatch: DispatchFunction) => {
  localStorage.getItem("currentUser");
  const cachedUser = localStorage.getItem("currentUser");
  if (cachedUser) {
    const userData = JSON.parse(cachedUser) as UserData;
    const isExpired = isCacheExpired(userData.saveTime);
    if (isExpired) {
      localStorage.removeItem("currentUser");
      return;
    }
    siteService.setToken(userData);
    dispatch({
      type: "SET_USER",
      data: userData,
    });
  }
};

export const logout = () => async (dispatch: DispatchFunction) => {
  siteService.setToken({ token: null });
  dispatch({
    type: "LOGOUT",
  });
};

export default reducer;
