import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

const useClickyOptions = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  inputRef?: React.RefObject<HTMLDivElement> | undefined
) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const handleClick = (e: Event) => {
    if (!wrapperRef.current?.contains(e.target as Node)) {
      setOptionsOpen(false);
    } else {
      if (inputRef?.current?.contains(e.target as Node)) {
        setOptionsOpen(!optionsOpen);
      } else {
        setOptionsOpen(true);
      }
    }
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return [optionsOpen, setOptionsOpen] as [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ];
};

export default useClickyOptions;
