import React from "react";
import phaseStyles from "../../styles/siteInspect/phase.module.css";
import { UnavailableIcon } from "../icons/icons";

const MarkBlockedButton = ({ toggle }: { toggle: () => void }) => {
  return (
    <div className={phaseStyles.markBlockedContainer}>
      <button onClick={toggle}>
        <UnavailableIcon />
        Merkkaa estyneeksi
      </button>
      <p>
        Merkkaa näin jos kohde on toistaiseksi estynyt siten, ettei seuraavia
        vaiheita voi vielä suorittaa.
      </p>
    </div>
  );
};
export default MarkBlockedButton;
