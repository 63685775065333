import { Popup } from "react-map-gl";
import { Link } from "react-router-dom";
import mapStyles from "../../styles/map.module.css";
import { Coordinates, MarkerProperties } from "../../types";
import { ClockIcon, LocationIcon } from "../icons/icons";
import { useDispatch } from "react-redux";
import { getSite } from "../../reducers/siteReducer";

type PopupProps = {
  coords: Coordinates;
  properties: MarkerProperties;
  close: () => void;
};

function MarkerPopup({ coords, properties, close }: PopupProps) {
  const [long, lat] = coords;
  const dispatch = useDispatch();
  return (
    <Popup
      latitude={lat}
      longitude={long}
      closeButton={false}
      closeOnClick={true}
      onClose={close}
      anchor="bottom"
      className={mapStyles.popupContainer}
    >
      <div className={mapStyles.popup}>
        <h3>{properties.name}</h3>
        <div>
          <span>
            <LocationIcon />
          </span>
          <p>{properties.address}</p>
        </div>
        <div>
          <span>
            <ClockIcon />
          </span>
          <p>{properties.displayPhase}</p>
        </div>
        <Link to={`/sites/${properties.id}`}>
          <button onClick={() => dispatch(getSite(properties.id))}>
            Tarkastele
          </button>
        </Link>
      </div>
    </Popup>
  );
}

export default MarkerPopup;
