import React from "react";
import phaseStyles from "../../styles/siteInspect/phase.module.css";
import { UnavailableIcon } from "../icons/icons";

const SiteBlockedText = ({ toggle }: { toggle: () => void }) => {
  return (
    <div className={phaseStyles.blockedContainer}>
      <p>
        <UnavailableIcon />
        Kohde on merkattu estyneeksi
      </p>
      <button onClick={toggle}>Poista este</button>
    </div>
  );
};
export default SiteBlockedText;
