import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setLoading } from "../../reducers/authReducer";
import styles from "../../styles/login.module.css";

const Login = () => {
  const [password, setPassword] = useState("");
  const isLoading: boolean = useSelector(
    (state: { auth: { isLoading: boolean } }) => state.auth.isLoading
  );
  const dispatch = useDispatch();
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isLoading) return;
    dispatch(setLoading(true));
    dispatch(login({ password }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`${styles.loginFormContainer} ${
        isLoading ? styles.loading : ""
      }`}
    >
      <h3 className={styles.mainHeader}>Kirjaudu sisään</h3>
      <h3 className={styles.logo}>Mediaterra</h3>
      <div className={styles.inputContainer}>
        <label>Salasana</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="••••••••"
          type="password"
        />
      </div>
      <button disabled={isLoading} type="submit">
        Kirjaudu
      </button>
    </form>
  );
};
export default Login;
