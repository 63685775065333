import React from "react";
import { Comment } from "../../../types";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";

const SiteComment = ({ comment }: { comment: Comment }) => {
  const { content, sender, timestamp } = comment;
  return (
    <div className={phaseStyles.commentWrapper}>
      <p className={phaseStyles.sender}>{sender}</p>
      <div className={phaseStyles.comment}>
        <p className={phaseStyles.text}>{content}</p>
        <p className={phaseStyles.date}>
          {new Date(timestamp).toLocaleDateString("fi")}
        </p>
      </div>
    </div>
  );
};
export default SiteComment;
