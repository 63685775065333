import { Expression } from "mapbox-gl";

export const clusterLayer = {
  id: "clusters",
  type: "circle" as const,
  source: "sites",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": "#C08432",
    "circle-radius": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0, // when zoom is 0, each feature's circle radius = 50
      20,
      15, // when zoom is 15, set each feature's circle radius to the value of its "rating" property
      ["min", 70, ["+", 20, ["*", 2, ["get", "point_count"]]]],
    ] as Expression,
  },
};

export const unClusteredLayer = {
  id: "unclustered-points",
  type: "circle" as const,
  source: "sites",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#C08432",
    "circle-radius": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0, // when zoom is 0, each feature's circle radius = 50
      20,
      15, // when zoom is 10, set each feature's circle radius to the value of its "rating" property
      15,
    ] as Expression,
  },
};
