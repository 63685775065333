import React from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import { GoBackIcon } from "../../icons/icons";

interface PhaseProps {
  phase: string;
  description?: string;
  phaseNumber: number;
  goBack?: () => void;
}

const PreviousPhase = ({
  phase,
  phaseNumber,
  description,
  goBack,
}: PhaseProps) => {
  return (
    <div className={phaseStyles.phase}>
      <div>
        {goBack && <p className={phaseStyles.description}>Edellinen vaihe</p>}
        <p className={phaseStyles.name}>
          {phaseNumber}. {phase}
        </p>
        {description && (
          <p className={phaseStyles.description}>{description}</p>
        )}
      </div>
      {goBack && (
        <button className={phaseStyles.actionButton} onClick={goBack}>
          <GoBackIcon />
          Palaa vaiheeseen
        </button>
      )}
    </div>
  );
};
export default PreviousPhase;
