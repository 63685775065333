import siteService from "../services/sites";
import {
  DispatchFunction,
  NewSite,
  ReducerAction,
  SiteCategory,
  NewSiteCategory,
  Site,
} from "../types";

type AdminState = {
  categories: SiteCategory[];
  chosenCategory: SiteCategory | null;
  sites: Site[];
  siteToEdit: Site | null;
};

const initialState: AdminState = {
  categories: [],
  chosenCategory: null,
  sites: [],
  siteToEdit: null,
};

const reducer = (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case "SET_CATEGORIES": {
      const categories = action.data as SiteCategory[];
      return { ...state, categories };
    }
    case "SET_CHOSEN_CATEGORY": {
      const { sites, category } = action.data;
      return { ...state, sites, chosenCategory: category };
    }
    case "DELETE_MANY_SITES": {
      const siteIds = action.data as string[];
      const sites = state.sites.filter((s) => !siteIds.includes(s.id));
      const sampleSite = state.sites.find((s) => siteIds.includes(s.id));
      const category = sampleSite ? sampleSite.category : null;
      const N = siteIds.length;
      const categories = state.categories.map((c) =>
        c.id === category?.id ? { ...c, siteCount: (c.siteCount ?? 0) - N } : c
      );
      return { ...state, sites, categories };
    }
    case "DELETE_CATEGORY": {
      const categoryId = action.data;
      const newCategories = state.categories.filter((c) => c.id !== categoryId);
      return { ...state, categories: newCategories };
    }
    case "CREATE_SITE": {
      const site = action.data;
      const categories = state.categories.map((c) =>
        c.id === site.category.id
          ? {
              ...c,
              siteCount: (c.siteCount ?? 0) + 1,
            }
          : c
      );
      return { ...state, categories };
    }
    case "SET_SITE_TO_EDIT": {
      const siteToEdit = action.data;
      const chosenCategory = siteToEdit.category;
      return { ...state, siteToEdit, chosenCategory };
    }
    case "RESET_SITE_TO_EDIT": {
      return { ...state, siteToEdit: null };
    }
    case "CREATE_CATEGORY": {
      const newCategory = action.data;
      return { ...state, categories: [...state.categories, newCategory] };
    }
    default:
      return state;
  }
};

export const getCategories = () => async (dispatch: DispatchFunction) => {
  const categories = (await siteService.getSiteCategories()) as SiteCategory[];
  dispatch({
    type: "SET_CATEGORIES",
    data: categories,
  });
};

export const initChosenCategory =
  (categoryId: string) =>
  async (dispatch: DispatchFunction, getState: () => { admin: AdminState }) => {
    const state = getState();
    const category = state.admin.categories.find((c) => c.id === categoryId);
    const sites = await siteService.getSitesForCategory(categoryId);
    dispatch({
      type: "SET_CHOSEN_CATEGORY",
      data: { sites, category },
    });
  };

export const setSiteToEdit =
  (siteId: string) => async (dispatch: DispatchFunction) => {
    const site = (await siteService.getSite(siteId)) as Site;
    dispatch({
      type: "SET_SITE_TO_EDIT",
      data: site,
    });
  };

export const resetSiteToEdit = () => async (dispatch: DispatchFunction) => {
  dispatch({
    type: "RESET_SITE_TO_EDIT",
  });
};

export const setChosenCategory =
  (category: SiteCategory) => async (dispatch: DispatchFunction) => {
    if (!category.id) return;
    const sites = await siteService.getSitesForCategory(category.id);
    dispatch({
      type: "SET_CHOSEN_CATEGORY",
      data: { sites, category },
    });
  };

export const createSite =
  (data: NewSite) => async (dispatch: DispatchFunction) => {
    const site = await siteService.createSite(data);
    dispatch({
      type: "CREATE_SITE",
      data: site,
    });
  };

export const editSite =
  (id: string, data: NewSite) => async (dispatch: DispatchFunction) => {
    const site = await siteService.editSite(id, data);
    dispatch({
      type: "EDIT_SITE",
      data: site,
    });
  };

export const createCategory =
  (data: NewSiteCategory) => async (dispatch: DispatchFunction) => {
    const category = await siteService.createCategory(data);
    dispatch({
      type: "CREATE_CATEGORY",
      data: category,
    });
  };

export const deleteManySites =
  (siteIds: string[]) => async (dispatch: DispatchFunction) => {
    await siteService.deleteManySites(siteIds);
    dispatch({
      type: "DELETE_MANY_SITES",
      data: siteIds,
    });
  };

export const deleteCategory =
  (categoryId: string) => async (dispatch: DispatchFunction) => {
    await siteService.deleteCategory(categoryId);
    dispatch({
      type: "DELETE_CATEGORY",
      data: categoryId,
    });
  };

export default reducer;
