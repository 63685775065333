import styles from "../../styles/map.module.css";

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactMapGL, {
  GeolocateControl,
  Layer,
  MapEvent,
  MapRef,
  Source,
} from "react-map-gl";
import { FeatureCollection } from "geojson";
import { Site, ViewportType } from "../../types";
import { clusterLayer, unClusteredLayer } from "../../layers";
import { getCustomMarkerArray } from "../../utils/renderUtils";
import { LngLatBounds } from "mapbox-gl";
import { GoBackIcon } from "../icons/icons";
import { MAX_CLUSTER_ZOOM_LEVEL } from "../../utils/mapConstants";

const EmbeddedMap = ({
  data,
  chosenSite,
}: {
  data?: FeatureCollection;
  chosenSite?: Site;
}) => {
  const [zoomedSiteId, setZoomedSiteId] = useState<string | null>(null);
  const mapRef = useRef<MapRef | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [markers, setMarkers] = useState<JSX.Element[]>([]);
  const [viewport, setViewport] = useState<ViewportType>({
    width: "100%",
    height: "100%",
    longitude: 25.0654,
    latitude: 60.4973,
    zoom: 5,
    transitionDuration: 1000,
  });

  const handleClick = (event: MapEvent) => {
    const { features } = event;
    if (!mapRef.current) return;
    if (!features || features.length === 0) return;
    const f = features[0];
    if (f.properties.cluster) {
      const clusterId = f.properties.cluster_id;
      const src = mapRef.current.getMap().getSource("sites");
      src.getClusterExpansionZoom(clusterId, (err: string, z: number) => {
        if (err) return;
        setViewport({
          ...viewport,
          longitude: f.geometry.coordinates[0],
          latitude: f.geometry.coordinates[1],
          zoom: z + 1,
          transitionDuration: 500,
        });
      });
    }
  };

  useEffect(() => {
    if (chosenSite && zoomedSiteId !== chosenSite.id) {
      const coords = chosenSite.location.coordinates;
      setViewport({
        ...viewport,
        longitude: coords[0],
        latitude: coords[1],
        zoom: 15,
        transitionDuration: 500,
      });
      setZoomedSiteId(chosenSite.id);
    }
  }, [chosenSite, viewport, zoomedSiteId]);

  useEffect(() => {
    const bounds = mapRef.current?.getMap()?.getBounds() as LngLatBounds;
    if (!data || !bounds) return;
    const arr = getCustomMarkerArray(data.features, bounds);
    setMarkers(arr);
  }, [data, viewport]);

  const { zoom } = viewport;
  const geolocateControlStyle = {
    right: 10,
    top: 10,
  };

  return (
    <div ref={containerRef} className={`mapboxgl-map ${styles.mapBlock}`}>
      {chosenSite && (
        <div className={styles.goBackBlock}>
          <Link to={`/sites/`} className={styles.backButton}>
            <GoBackIcon />
          </Link>
          <h3 className={styles.siteName}>{chosenSite.name}</h3>
        </div>
      )}
      <ReactMapGL
        {...viewport}
        mapStyle={"mapbox://styles/jleevi/ckwbsfgil7ipj15p1v2c15gaa"}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onViewportChange={setViewport}
        onClick={handleClick}
        asyncRender={true}
        ref={mapRef}
      >
        <GeolocateControl
          style={geolocateControlStyle}
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          showUserLocation={true}
          showAccuracyCircle={true}
          auto={true}
        />
        {data && (
          <Source
            id="sites"
            type="geojson"
            data={data}
            cluster={true}
            clusterMaxZoom={MAX_CLUSTER_ZOOM_LEVEL}
            clusterRadius={50}
          >
            {zoom > MAX_CLUSTER_ZOOM_LEVEL && markers ? (
              [...markers]
            ) : (
              <>
                <Layer {...clusterLayer} />
                <Layer {...unClusteredLayer} />
              </>
            )}
          </Source>
        )}
      </ReactMapGL>
    </div>
  );
};

export default EmbeddedMap;
