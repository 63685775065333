import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteManySites,
  initChosenCategory,
} from "../../reducers/adminReducer";
import { Link } from "react-router-dom";
import styles from "../../styles/misc/admin.module.css";
import { Site, SiteCategory } from "../../types";
import { CheckIcon, PlusIcon } from "../icons/icons";
import SiteListTile from "./SiteListTile";
import ConfirmPopup from "../sites/ConfirmPopup";

const Sites = ({
  categoryId,
  category,
  sites,
}: {
  categoryId: string;
  category: SiteCategory | undefined;
  sites: Site[];
}) => {
  const dispatch = useDispatch();
  if (!category) {
    dispatch(initChosenCategory(categoryId));
  }

  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const [sitesToConfirmDelete, setSitestoConfirmDelete] = useState<Site[]>([]);

  const isAllSelected = () => {
    return selectedSites.length === sites.length;
  };

  const toggleSelectAll = () => {
    const allSelected = isAllSelected();
    if (allSelected) {
      setSelectedSites([]);
    } else {
      setSelectedSites(sites);
    }
  };

  const confirmDeleteSites = (sitesToDelete: Site[]) => {
    setConfirmPopupOpen(true);
    setSitestoConfirmDelete(sitesToDelete);
  };

  const deleteSites = (sitesToDelete: Site[]) => {
    const siteIds = sitesToDelete.map((s) => s.id);
    dispatch(deleteManySites(siteIds));
    setSelectedSites([]);
  };

  const deleteSite = (site: Site) => {
    confirmDeleteSites([site]);
  };

  const selectSite = (site: Site) => {
    const updatesSites = [...selectedSites, site];
    setSelectedSites(updatesSites);
  };

  const unSelectSite = (site: Site) => {
    const updatesSites = selectedSites.filter((s) => s.id !== site.id);
    setSelectedSites(updatesSites);
  };

  const isSiteSelected = (site: Site) => {
    return selectedSites.includes(site);
  };

  const deleteConfirmCallback = (confirmed: boolean) => {
    if (confirmed) {
      deleteSites(sitesToConfirmDelete);
    }
    setConfirmPopupOpen(false);
  };

  const getConfirmDeleteText = () => {
    const N = sitesToConfirmDelete.length;
    if (N === 1) {
      const s = sitesToConfirmDelete[0];
      return `Haluatko varmasti poistaa kohteen "${s.name}" ?`;
    } else {
      return `Haluatko varmasti poistaa ${N} kohdetta?`;
    }
  };

  return (
    <div className={styles.dataContainer}>
      <div className={styles.titleContainer}>
        <h3>{category?.name ?? "Ladataan..."}</h3>
        <Link to="/admin/createsite/">
          <button>
            <PlusIcon />
            Uusi kohde
          </button>
        </Link>
      </div>
      <div>
        {sites.length > 0 && (
          <div className={styles.chooseAllContainer}>
            <div
              onClick={toggleSelectAll}
              className={`${styles.checkbox} ${
                isAllSelected() ? styles.checked : ""
              }`}
            >
              <CheckIcon />
            </div>
            <p>Valitse kaikki</p>
            <input type="checkbox" hidden />
            {selectedSites.length > 0 && (
              <button onClick={() => confirmDeleteSites(selectedSites)}>
                Poista valitut
              </button>
            )}
          </div>
        )}
        {sites.map((s) => (
          <SiteListTile
            selectSite={() => selectSite(s)}
            unSelectSite={() => unSelectSite(s)}
            deleteSite={() => deleteSite(s)}
            selected={isSiteSelected(s)}
            key={s.id}
            site={s}
          />
        ))}
        {sites.length === 0 && (
          <h3 className={styles.noSitesDisclaimer}>Ei vielä kohteita</h3>
        )}
      </div>
      {confirmPopupOpen && (
        <ConfirmPopup
          callback={deleteConfirmCallback}
          confirmText={getConfirmDeleteText()}
        />
      )}
    </div>
  );
};
export default Sites;
