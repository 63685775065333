import React, { useEffect } from "react";
import { Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SiteInspect from "./components/sites/inspect/SiteInspect";
import { initSites } from "./reducers/siteReducer";
import "mapbox-gl/dist/mapbox-gl.css";
import { Site, UserData } from "./types";
import MapContainer from "./components/map/MapContainer";
import { LoginRoute, PrivateRoute } from "./utils/routing";
import Login from "./components/auth/Login";
import { initialLogin } from "./reducers/authReducer";
import Notifications from "./components/notifications/Notifications";
import AdminFrontPage from "./components/admin/AdminFrontpage";

function App() {
  const dispatch = useDispatch();
  const [chosenSite, currentUser]: [Site | undefined, UserData | null] =
    useSelector(
      (state: {
        sites: {
          chosenSite: Site;
        };
        auth: {
          currentUser: UserData | null;
        };
      }) => [state.sites.chosenSite, state.auth.currentUser]
    );

  useEffect(() => {
    dispatch(initialLogin());
  }, [dispatch]);

  const isAuthenticated = currentUser !== null;
  //const isAdmin = currentUser.username === "REG_ADMIN";

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(initSites());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <div className="app">
      <Notifications />
      <Switch>
        <LoginRoute isAuthenticated={isAuthenticated} path="/login">
          <Login />
        </LoginRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/sites/:id">
          <MapContainer chosenSite={chosenSite} />
          <SiteInspect />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/admin">
          <AdminFrontPage />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/">
          <MapContainer />
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default App;
