import React, { useState } from "react";
import { Comment } from "../../../types";

import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import SiteComment from "./SiteComment";
import { SendIcon } from "../../icons/icons";

interface CommentsProps {
  comments: Comment[];
  sendNew: (arg: string) => void;
}

const SiteComments = ({ comments, sendNew }: CommentsProps) => {
  const [commentText, setCommentText] = useState("");

  const sendComment = () => {
    sendNew(commentText);
    setCommentText("");
  };

  const hasComments = comments.length > 0;
  const reversedComments = comments.slice().reverse();
  return (
    <div className={phaseStyles.commentAreaContainer}>
      <p className={phaseStyles.commentInfoTitle}>
        {hasComments ? "Kommentit" : "Ei kommentteja"}
      </p>
      <div className={phaseStyles.commentsContainer}>
        {reversedComments.map((c) => (
          <SiteComment key={c.timestamp.toString()} comment={c} />
        ))}
      </div>
      <div className={phaseStyles.textAreaContainer}>
        <textarea
          className={phaseStyles.textArea}
          placeholder="Kirjoita kommentti..."
          onChange={(event) => setCommentText(event.target.value)}
          value={commentText}
        />
        <button onClick={sendComment}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};
export default SiteComments;
