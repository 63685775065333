import React, { useRef } from "react";
import useClickyOptions from "../../hooks/useClickOptions";
import filterStyles from "../../styles/siteList/filters.module.css";
import { SearchIcon } from "../icons/icons";
import SearchInput from "./SearchInput";

type SearchFilterProps = {
  placeholder: string;
  currentValue: string | null;
  handleChange: (v: string) => void;
};

const SearchFilter = ({
  placeholder,
  currentValue,
  handleChange,
}: SearchFilterProps) => {
  const wrapperRef = useRef(null);
  let inputRef = undefined;
  const [inputOpen, setInputOpen] = useClickyOptions(wrapperRef, inputRef);
  return (
    <div
      ref={wrapperRef}
      className={`${filterStyles.filter} ${filterStyles.searchFilter}`}
    >
      <span className={filterStyles.filterIcon}>
        <SearchIcon />
      </span>
      {inputOpen && (
        <SearchInput
          handleChange={handleChange}
          setRef={(ref: React.MutableRefObject<null>) => (inputRef = ref)}
          placeholder={placeholder}
          value={currentValue}
          close={() => setInputOpen(false)}
        />
      )}
    </div>
  );
};

export default SearchFilter;
