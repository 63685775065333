import React from "react";
import phaseStyles from "../../../styles/siteInspect/phase.module.css";
import { CheckIcon } from "../../icons/icons";

interface PhaseProps {
  phase: string;
  phaseNumber: number;
  markCompleted: () => void;
}

const ActivePhase = ({ phase, phaseNumber, markCompleted }: PhaseProps) => {
  return (
    <div className={`${phaseStyles.phase} ${phaseStyles.activePhase}`}>
      <div>
        <p className={phaseStyles.description}>Työn alla</p>
        <p className={phaseStyles.name}>
          {phaseNumber}. {phase}
        </p>
      </div>
      <button className={phaseStyles.actionButton} onClick={markCompleted}>
        <CheckIcon />
        Merkkaa suoritetuksi
      </button>
    </div>
  );
};
export default ActivePhase;
