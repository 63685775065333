import React, { useRef } from "react";
import filterStyles from "../../styles/siteList/filters.module.css";
import { SearchIcon } from "../icons/icons";

type PropTypes = {
  placeholder: string;
  setRef: (arg: React.MutableRefObject<null>) => void;
  value: string | null;
  handleChange: (v: string) => void;
  close: () => void;
};

const SearchInput = ({
  placeholder,
  setRef,
  handleChange,
  value,
  close,
}: PropTypes) => {
  const inputRef = useRef(null);
  const handlesubmit = (query: string) => {
    handleChange(query);
  };
  setRef(inputRef);
  return (
    <div ref={inputRef} className={filterStyles.searchInputBlock}>
      <SearchIcon />
      <input
        onChange={(ev) => handlesubmit(ev.target.value)}
        name="query"
        autoFocus={true}
        placeholder={placeholder}
        defaultValue={value ?? ""}
      />
      <button onClick={close}>Ok</button>
    </div>
  );
};

export default SearchInput;
