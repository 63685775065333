import React from "react";
import styles from "../../styles/misc/admin.module.css";
import { Site } from "../../types";
import { CheckIcon, LargeNextIcon, TrashIcon } from "../icons/icons";
import { Link } from "react-router-dom";

type Props = {
  site: Site;
  selectSite: () => void;
  unSelectSite: () => void;
  deleteSite: () => void;
  selected: boolean;
};

const SiteListTile = ({
  site,
  selectSite,
  unSelectSite,
  deleteSite,
  selected,
}: Props) => {
  const toggleSelect = () => {
    if (selected) {
      unSelectSite();
    } else {
      selectSite();
    }
  };

  const getEditLink = () => {
    return "/admin/editsite/" + site.id;
  };

  return (
    <div>
      <div className={`${styles.tile} ${styles.siteTile}`}>
        <div className={styles.checkboxContainer}>
          <div
            onClick={toggleSelect}
            className={`${styles.checkbox} ${selected ? styles.checked : ""}`}
          >
            <CheckIcon />
          </div>
        </div>
        <p className={styles.header}>{site.name}</p>
        <p>{site.location.address.city}</p>
        <button onClick={deleteSite} className={styles.deleteButton}>
          <TrashIcon />
        </button>
        <Link to={getEditLink}>
          <button>
            <LargeNextIcon />
          </button>
        </Link>
      </div>
    </div>
  );
};
export default SiteListTile;
