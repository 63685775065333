import axios from "axios";
import {
  PhaseUpdateData,
  FilterState,
  NewComment,
  UserData,
  NewSite,
  NewSiteCategory,
} from "../types";

const baseUrl = "/api/sites";
const adminUrl = "/api/admin/sites";

let authToken: string | null = null;

const setToken = (userData: UserData) => {
  const { token } = userData;
  if (token) {
    authToken = `Bearer ${token}`;
  } else {
    authToken = null;
  }
};

const getAuthConfig = () => ({
  headers: {
    Authorization: authToken ?? "",
  },
});
const getSite = async (id: string) => {
  const config = getAuthConfig();
  const response = await axios.get(`${baseUrl}/` + id, config);
  return response.data;
};

const updatePhase = async (id: string, data: PhaseUpdateData) => {
  const config = getAuthConfig();
  const response = await axios.put(
    `${baseUrl}/${id}/phase`,
    { ...data },
    config
  );
  return response.data;
};

const addComment = async (id: string, data: NewComment) => {
  const config = getAuthConfig();
  const response = await axios.post(
    `${baseUrl}/${id}/comment`,
    { ...data },
    config
  );
  return response.data;
};

const setBlocked = async (id: string, blocked: boolean) => {
  const config = getAuthConfig();
  const response = await axios.post(
    `${baseUrl}/${id}/blocked`,
    { blocked },
    config
  );
  return response.data;
};

const getSites = async (filters?: FilterState) => {
  const config = getAuthConfig();
  const response = await axios.get(baseUrl, {
    ...config,
    params: { ...filters },
  });
  return response.data;
};

const getSitesForCategory = async (category: string) => {
  const config = getAuthConfig();
  const response = await axios.get(`${baseUrl}/category/${category}`, config);
  return response.data;
};

const getSiteCategories = async () => {
  const config = getAuthConfig();
  const response = await axios.get(`${baseUrl}/categories`, config);
  return response.data;
};

const getFilterOptions = async (category: string | null) => {
  const config = getAuthConfig();
  const response = await axios.get(`${baseUrl}/filter-options`, {
    ...config,
    params: { category },
  });
  return response.data;
};

const createSite = async (data: NewSite) => {
  const config = getAuthConfig();
  const response = await axios.post(adminUrl, { ...data }, config);
  return response.data;
};

const deleteManySites = async (siteIds: string[]) => {
  const config = getAuthConfig();
  const response = await axios.post(
    `${adminUrl}/delete-many`,
    { sites: siteIds },
    config
  );
  return response.data;
};

const createCategory = async (data: NewSiteCategory) => {
  const config = getAuthConfig();
  const response = await axios.post(
    `${adminUrl}/category`,
    { ...data },
    config
  );
  return response.data;
};

const deleteCategory = async (categoryId: string) => {
  const config = getAuthConfig();
  await axios.delete(`${adminUrl}/category/${categoryId}`, config);
};

const editSite = async (siteId: string, site: NewSite) => {
  const config = getAuthConfig();
  const response = await axios.put(`${adminUrl}/${siteId}`, site, config);
  return response.data;
};

export default {
  getSite,
  getSites,
  getSitesForCategory,
  updatePhase,
  addComment,
  getFilterOptions,
  setToken,
  getSiteCategories,
  createSite,
  createCategory,
  deleteManySites,
  deleteCategory,
  editSite,
  setBlocked,
};
